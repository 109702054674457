import $ from 'jquery'
//console.log(!window.jQuery)
/**
 * установка фонового изображения для элемента
 * из атрибута `data-background`.
 *
 * фоновое изображение (background-image)
 * лучше работает для адаптивных блоков
 *
 * конечно дополнительно необходимо
 * добавить css-стили для определения поведения.
 *
 * ```html
 *  <div
 *    class="has-background-image"
 *    data-background="/assets/images/bg.png">
 *    ...
 *  </div>
 * ```
 * @requires jQuery
 */
function init() {
  const $images = $('.has-background-image')
  if ($images.length) {
    $images.each(function(idx, el){
      let image = $(el).data('background')
      if (image !== undefined) {
        $(this).css('background-image', `url(${image})`)
      }
    })
  }
}

export default {
  start: function(){
    init()
  }
}
