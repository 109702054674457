<template lang="pug">
  // форма в контактах
  .hero-contact-form.hero: .hero-body: .container: validation-observer(v-slot="{valid, invalid}")
    .content: h2.has-text-centered Форма обратной связи
    b-notification.is-success.content.has-background-primary.has-text-centered(v-if="isSuccess" :closable="false")
      p.title.is-5 Спасибо! Ваша заявка отправлена.
      p.success-message как только получим вашу заявку, обязательно перезвоним
      p: button.button.is-success(@click.prevent="closeSuccess") понятно
    b-notification.is-danger(v-if="hasErrors")
      p.title.is-4 Ошибка!
      p не удалось обработать запрос, попробуйте еще раз, если ошибка повторяется перезагрузите страницу
    form.form(@submit.prevent="submit" ref="fc_form" name="fc_form" v-if="isNotSuccess")
      input(type="hidden" name="WEB_FORM_ID" value="1")
      input(type="hidden" name="form_hidden_39" value="да")
      validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required" name="Имя и фамилия")
        b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
          b-input(name="form_text_41" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия *")
        p.help.is-danger(v-if="invalid")  {{ errors[0] }}  
      validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required" name="Телефон")
        b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
          b-input(name="form_text_38" :disabled="isLock" v-model="formData.phone" placeholder="Телефон *")
        p.help.is-danger(v-if="invalid")  {{ errors[0] }}  
      validation-provider(v-slot="{errors, valid, dirty, invalid, pristine }" rules="required|email" name="Email")
        b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
          b-input(name="form_email_2" :disabled="isLock" v-model="formData.email" placeholder="Email *")
        p.help.is-danger(v-if="invalid")  {{ errors[0] }}
      validation-provider(v-slot="{errors, valid, dirty, invalid, pristine }" rules="required" name="Ваше сообщение")
        b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
          b-input(name="form_textarea_1" type="textarea" placeholder="Ваше сообщение * " v-model="formData.massage")
        p.help.is-danger: span(v-if="invalid")  {{ errors[0] }}  
      p.has-text-danger * - обязательные поля.
      br
      p.
        Отправляя форму вы соглашаетесь на обработку ваших персональных данных
        в соответствие с <a href="/assets/files/Personal-data-processing-agreement.pdf" target="_blank">пользовательским соглашением сайта</a>. Передаваемая
        контактная информация используется исключительно для связи с Вами.
      br
      p: b-field
        button.button.is-primary(type="submit" :disabled="isLock || invalid" :class="{ 'is-loading': isLock }") отправить

</template>
<script>
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
export default {
  store,
  data () {
    return {
      status: 'pristine',
      formId: 1,
      formData: {
        name: '',
        email: '',
        massage: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapGetters(['isLock']),
    isSuccess() {
      return this.status === 'success'
    },
    hasErrors() {
      return this.status === 'error'
    },
    isNotSuccess() {
      return this.status !== 'success'
    }
  },
  methods: {
    ...mapMutations(['lock', 'unlock']),
    submit () {
      this.lock()
      const form = new FormData(this.$refs.fc_form)
      this.$http.post('/platform/api/forms/1/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.unlock()
        this.status = 'success'
        this.formData.name = ''
        this.formData.phone = ''
        this.formData.email = ''
        this.formData.massage = ''

        this.$parent.$emit('success')
      }, errors => {
        this.unlock()
        this.status = 'error'
        this.$buefy.toast.open({
          type: 'is-danger',
          message: `Ошибка! ${errors.status}`
        })
      })
    },
    closeSuccess() {
      this.status = 'pristine'
      this.$emit('close')
    }
  }
}
</script>