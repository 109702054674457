import $ from 'jquery'

/**
 * УПРАВЛЕНИЕ МЕНЮ ДОЛЖНО БЫТЬ
 * МОНОЛИТНО, И РАЗМЕЩАТЬСЯ В ОДНОМ МОДУЛЕ
 * 
 * @deprecated требуется переработка и перенос в runtime/navbar.js
 * 
 */ 
function start () {
  console.warn('DEPRECATED: требуется замена и переработка!')
  
  /**
  * Инициализация триггеров
  */
  var dropConatiner = '.js-menu-list-dropdown'
  var navbarDropdown = '.js-menu-list-container'
  var linkButton = '.js-menu-list__link'
  var dropMobileConatiner = '.js-navbar-item-mobile'

  /**
  * выпадающее меню (desktop)
  * 
  * логика if ($('div'), а зетем $(function()) - это абсурд.
  * 
  * @deprecated разработка новой версии в src/runtime/navbar/navbar.js
  * 
  */
  if ($('div').is(dropConatiner)) {
    $(function() { // dom ready после поиска элемента в DOM?
    
    
    
      /**
       * Класс для управления 
       */ 
      var Accordion = function(el, multiple) {
        this.el = el || {}
        this.multiple = multiple || false
    
        var links = this.el.find(linkButton)
        links.on('click', {
          el: this.el,
          multiple: this.multiple,
        }, this.dropdown)
      }
      
      Accordion.prototype.dropdown = function(e) {
        var $el = e.data.el
        var $this = $(this)
        var $next = $this.next()
    
        $next.slideToggle()
        $this.parent().toggleClass('open')
    
        if (!e.data.multiple) {
          $el.find(navbarDropdown).not($next).slideUp().parent().removeClass('open')
        }
      }
      
      new Accordion($(dropConatiner), false);
    })
  }
  
  /**
   * мобильное меню
   */
  if ($('div').is(dropMobileConatiner)) {
    var returnButton = '.dropdown-return'
    $(function() {
      
      
      /**
       * Класс управления показом меню
       * 
       * функциональноть универсальна, не зависит
       * от уровня вложенности. События прикрепляются
       * ко всем найденным элементам и отрабатывают
       * независимо друг от друга.
       * 
       * Такого же эффекта можно достигнуть
       * используя querySelectorAll
       * 
       * Проблемы
       * 
       * - события не удаляются, если запустить скрипт 100 раз
       * то получить по 100 событий на каждом элементе. Но возможно
       * jQuery справляется с этой проблемой сам. Да, и получим 100 классов.
       * 
       */ 
      var Accordion = function(el, multiple) {
        this.el = el || {}
        this.multiple = multiple || false
    
        var links = this.el.find(linkButton)
        var linksReturn = this.el.find(returnButton)

        links.on('click', {
          el: this.el,
          multiple: this.multiple
        }, this.dropdown)
        
        linksReturn.on('click', {
          el: this.el,
          multiple: this.multiple
        }, this.dropdownReturn)
      }
      
      /**
       * показ подменю
       */
      Accordion.prototype.dropdown = function(e) {
        var $el = e.data.el
        var $this = $(this)
        var $next = $this.next()
        
        $next.animate({'width': 'toggle'})
        $this.parent().addClass('open')
    
        if (!e.data.multiple) {
          $el.find(navbarDropdown).not($next).parent().removeClass('open')
        }
      }
      
      /**
       * скрытие подменю
       */
      Accordion.prototype.dropdownReturn = function() {
        var $this = $(this)
        var $parent = $this.parent()
    
        $parent.animate({'width': 'toggle'})
        $parent.parent().removeClass('open')
      }
      
      new Accordion($(dropMobileConatiner), false)
    })
  }
}

export default {
  start
}
