import Vue from 'vue'
import OrderToAppointmentTrigger from '@/components/site/make-appointment/OrderToAppointmentTrigger';

function start() {
  new Vue({
    el: '.widget-make-appointment-trigger',
    render: h => h(OrderToAppointmentTrigger)
  })
}

export default {
  start
}
