import Swiper from 'swiper'
require('./style.scss')

function start() {
  /**
   * @see https://swiperjs.com/api/
   */
  return new Swiper('.js-index-list-doctor', {
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    loop: true,
    speed: 100,
    paginationClickable: true,
    slidesPerView: 5,
    spaceBetween: 30,
    pagination: {
      el: '.js-swiper-pagination-doctor',
      clickable: true,
      dynamicBullets: true
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      },
      1216: {
        slidesPerView: 5
      },
    }
  })
}

export default {
  start
}
