var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-to-appointment-form section"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var invalid = ref.invalid;
return [_c('p',{staticClass:"title is-4 has-text-centered has-text-weight-light"},[_vm._v("Записаться на приём")]),_c('br'),(_vm.isSuccess)?_c('b-notification',{staticClass:"is-success content has-background-primary has-text-centered",attrs:{"closable":false}},[_c('p',{staticClass:"title is-4"},[_vm._v("Спасибо! Ваша заявка отправлена.")]),_c('p',{staticClass:"success-message"},[_vm._v("как только получим вашу заявку, обязательно перезвоним")]),_c('p',[_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.closeSuccess($event)}}},[_vm._v("понятно")])])]):_vm._e(),(_vm.hasErrors)?_c('b-notification',{staticClass:"is-danger"},[_c('p',{staticClass:"title is-4"},[_vm._v("Ошибка!")]),_c('p',[_vm._v("не удалось обработать запрос, попробуйте еще раз, если ошибка повторяется перезагрузите страницу")])]):_vm._e(),(_vm.isNotSuccess)?_c('form',{ref:"os_form",staticClass:"form",attrs:{"name":"os_form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('input',{attrs:{"type":"hidden","name":"WEB_FORM_ID","value":"6"}}),_c('input',{attrs:{"type":"hidden","name":"form_text_54","value":"да"}}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
var invalid = ref.invalid;
var pristine = ref.pristine;
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': invalid && dirty, 'is-success': valid }}},[_c('b-input',{attrs:{"name":"form_text_51","disabled":_vm.isLock,"placeholder":"Имя и фамилия"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var invalid = ref.invalid;
var dirty = ref.dirty;
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': invalid && dirty, 'is-success': valid }}},[_c('b-input',{attrs:{"name":"form_text_52","disabled":_vm.isLock,"placeholder":"Телефон"},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"rules":"required","name":"Ваше сообщение"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var dirty = ref.dirty;
var invalid = ref.invalid;
var pristine = ref.pristine;
return [_c('b-field',{attrs:{"type":{'is-danger': invalid && dirty, 'is-success': valid }}},[_c('b-input',{attrs:{"name":"form_textarea_53","type":"textarea","placeholder":"Ваше сообщение * "},model:{value:(_vm.formData.massage),callback:function ($$v) {_vm.$set(_vm.formData, "massage", $$v)},expression:"formData.massage"}})],1),_c('p',{staticClass:"help is-danger"},[(invalid)?_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1)]),_c('p',{staticClass:"has-text-danger",staticStyle:{"margin-bottom":".75rem"}},[_vm._v("* - обязательные поля.")]),_c('p',{staticClass:"is-size-7"},[_vm._v("Отправляя форму вы соглашаетесь на обработку ваших персональных данных в соответствие с "),_c('a',{attrs:{"href":"/assets/files/Personal-data-processing-agreement.pdf","target":"_blank"}},[_vm._v("пользовательским соглашением сайта")]),_vm._v(". Передаваемая контактная информация используется исключительно для связи с Вами.")]),_c('br'),_c('p',[_c('b-field',[_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.isLock },attrs:{"type":"submit","disabled":_vm.isLock || invalid}},[_vm._v("отправить")])])],1)]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }