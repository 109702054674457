<template lang="pug">
  .order-to-service-doctor-trigger.button.is-success.is-outlined(@click.prevent="showForm")
    span.icon: i.far.fa-edit
    span Записаться на приём
</template>
<script>
  import OrderToServiceDoctorForm from '@/components/site/order-to-service-doctor/OrderToServiceDoctorForm'
  import store from '@/store'

  /**
   * кнопка триггер для
   * отображения формы записи
   *
   * <div class="widget-order-to-service-doctor-trigger">
   *    <order-to-service-doctor-trigger 
   *        service="<?= $doctor['NAME'] ?>"  
   *        doctor="<?= $ar?>"
   *    />
   * </div>
   */
  export default {
    store,
    props: {
      /**
       * услуга
       */ 
      service: {
        type: String,
        required: true
      },
      /**
       * доктор
       */ 
      doctor: {
        type: String,
        required: true
      }
    },
    methods: {
      showForm() {
        this.$buefy.modal.open({
          parent: this,
          width: 640,
          component: OrderToServiceDoctorForm,
          canCancel: ['x'], // закрытие только по кнопке `x`
          /**
           * "пробрасываем" свойства
           * в форму. в модальное окно
           */ 
          props: {
            doctor: this.doctor,
            service: this.service
          }
        })
      }
    }
  }
</script>
