import aboutLisenseGallery from './galleries/about-license-gallery'
import doctorsCertificateGallery from './galleries/doctors-certificate-gallery'
import innovativeMethodsGallery from './galleries/innovative-methods-of-treatment'

/**
 * при использовании runtime-логики
 * шаблон .pswp.pswp-footer следует подключить
 * в footer.php шаблона сайта
 *
 * Каждая отдельная инициализация галереи
 * размещается в отдельном файле
 */

function start() {
  /**
   * каждая галерея должна инициализироваться отдельым модулем (файлом)
   */ 
  aboutLisenseGallery.init('.about-license-gallery')
  doctorsCertificateGallery.init('.doctors-certificate-gallery')
  innovativeMethodsGallery.init('.innovative-methods-gallery')
}

export default {
  start
}
