import Vue from 'vue'
import OrderToServiceDoctorTrigger from '@/components/site/order-to-service-doctor/OrderToServiceDoctorTrigger'

function start() {
  
  /**
   * форма записи на прием должна подлючаться ко всем
   * возможным триггерам на странице
   * 
   */
  const triggers = document.querySelectorAll('.widget-order-to-service-doctor-trigger')
  triggers.forEach(el => {
    new Vue({
      components: {
        OrderToServiceDoctorTrigger
      }
    }).$mount(el)  
  })
}

export default {
  start
}

