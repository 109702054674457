import {initPhotoSwipeFromDOM} from '../pswp'

/**
 * Для каждой инициализации всегда создается
 * отдельный файл
 *
 * @param selector {string} HTML селектор
 */
function init(selector) {
  initPhotoSwipeFromDOM(selector)    
}

export default {
  init
}


