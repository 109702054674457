import Vue from 'vue'
import store from '@/store'
import ContactsForm from '@/components/site/form-contacts/ContactsForm'

require('./style.scss')

function start() {
  /**
   * поиск всех элементов на странице
   * и создание виджетов
   */
  document
    .querySelectorAll('.widget-contacts-form')
    .forEach((el) => {
      new Vue({
        el: el,
        store,
        render: h => h(ContactsForm)
      })
  })
}

export default {
  start
}
