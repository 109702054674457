<template lang="pug">
.platform-side-panel.side-panel-default(:class="{ 'is-active': isActive }")
  .platform-side-panel-item
    a.button.button-side-panel.open-side-panel(@click="toggle")
      span.icon
        img.image.is-24x24(src="/local/components/platform/side-panel/templates/.default/images/arrow.svg" alt="open")
      span.open-side-panel__text Скрыть панель
  .platform-side-panel-item
    a.button.button-side-panel.callback-trigger(@click.prevent="callback")
      span.icon
        img.image.is-24x24(src="/local/components/platform/side-panel/templates/.default/images/phone.svg" alt="Модальное окно")
      span.open-side-panel__text Обратный звонок
  .platform-side-panel-item
    a.button.button-side-panel(href="/services/")
      span.icon
        img.image.is-24x24(src="/local/components/platform/side-panel/templates/.default/images/ruler.svg" alt="Услуги")
      span.open-side-panel__text Услуги
</template>
<script>
import { CallbackForm } from '@/components'
import store from '@/store'
export default {
  store,
  components: {
    CallbackForm
  },
  data () {
    return {
      active: false
    }
  },
  computed: {
    isActive () {
      return this.active === true
    }
  },
  methods: {
    callback () {
      console.log('callback')
      this.$buefy.modal.open({
        parent: this,
        component: CallbackForm
      })
    },
    toggle () {
      this.active = !this.active
    }
  }
}
</script>
<style lang="sass" scoped>
  @import 'vars'
  .platform-side-panel
    position: fixed
    right: -120px
    top: 50%
    width: 156px
    height: auto
    transition: all .5s ease
    z-index: 1
    background: $primary

    .platform-side-panel-item
    border-bottom: 1px solid transparent
    .button-side-panel
      width: 156px
      background: $primary
      border: none
      justify-content: normal
    &:hover
      background: darken($primary, 10%)

  .open-side-panel
    .icon
      transition: all .24s ease

  .open-side-panel__text
    padding-left: .5rem
    display: inline-block
    color: #fff
    font-weight: 400
    font-size: .875rem

  .platform-side-panel.is-active
    right: 0
    .open-side-panel
      .icon
        transform: rotate(180deg)
</style>
