<template lang="pug">
  .order-to-appointment-trigger.button.is-success(@click.prevent="showForm")
    span.icon.has-text-white: i.far.fa-edit
    span Записаться на прием
</template>
<script>
  import OrderToAppointmentForm from '@/components/site/make-appointment/OrderToAppointmentForm'
  import store from '@/store'

  /**
   * кнопка триггер для
   * отображения формы записи на прием
   *
   * <div class="widget-make-appointment-trigger"></div>
   */
  export default {
    store,
    methods: {
      showForm() {
        this.$buefy.modal.open({
          parent: this,
          width: 640,
          component: OrderToAppointmentForm,
          canCancel: ['x'], // закрытие только по кнопке `x`
        })
      }
    }
  }
</script>
