
import Vue from 'vue'
import store from '../../store'
import { CallbackForm } from '../../components'

import { gsap, TweenMax } from "gsap"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(CSSRulePlugin, ScrollToPlugin)

import * as ScrollMagic from "scrollmagic"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"

ScrollMagicPluginGsap(ScrollMagic, TweenMax)

/**
 * ВАЖНО!
 *
 * @todo /src/runtime/bitrix/menu/nav-company.js
 * @todo требуется убрать дублирование и совместить функциональность
 * @todo добавить поддержку "выпадающего" меню для мобильных
 *
 */


/**
 * экспериментальный компонент
 * (только для мобильной версии меню)
 *
 * инициализируется во вложенной структуре
 * меню битрикс (компонента меню битрикс)
 *
 * @todo создавать компонент отдельно
 *
 * МОБИЛЬНОЕ МЕНЮ
 *
 * @todo класс активности is-active
 *
 */
Vue.component('dropdown-menu-mobile', {
  props: {
    menuActive: {

    }
  },
  data(){
    return {
      active: false,
      open: false
    }
  },
  methods: {
    back() {
      console.log('back!')
      this.active = false
      this.$emit('close')
    }
  },
  template: `
    <div class="navbar-item navbar-item-mobile js-navbar-item-mobile">

      <slot name="link"></slot>

      <div class="menu-list__dropdown js-menu-list-container" style="display: none">
          <div @click.prevent.stop="back" class="dropdown-return">
            <span>вернуться назад</span>
          </div>
          <slot></slot>
      </div>
    </div>
   `
})


/**
 * WIP. В разработке
 *
 * Управление выпадающими блоками (dropdown) меню
 * (только для desktop версии меню)
 *
 * Меню отрисовывается стандартным компонентом Битрикс,
 * при этом
 *
 * - все меню оборачивается в компонент Vue (см. ниже в функции start()
 * - dropdown блоки обернуты в компоненты `dropdown-menu-desktop`
 *
 * То есть данные компонент управляет показом каждого
 * отдельного dropdown блока изолированно.
 *
 * Получается что меню состоит из трех частей
 *
 * - битрикс компонент и шаблон
 * - vue компонент для всего меню
 * - vue компонент для dropdown списков
 *
 * Это достаточно сложно, но в битрикс по другому не будет.
 *
 * Чтобы передавать состояние меню можно использовать Vuex
 * или общую шину событий.
 *
 * @todo замена для nav-company.js
 * @todo is-active
 * @todo gsap animation
 */
Vue.component('dropdown-menu-desktop', {
  data() {
    return {
      dropdownIsActive: true,
      isActive: false
    }
  },
  computed: {

  },

  created(){
    this.$parent.$on('close', () => {
      this.isActive = false
    })
  },
  methods: {
    toggleDropdown() {
      /**
       * можно оповещать родителя
       * о том закрыто или открыто меню
       *
       * если меню открыто, то можно
       * закрыть все остальные меню
       *
       * если "я" активен, то все остальные
       * должны быть закрыты
       *
       */
      this.isActive = !this.isActive

      console.log('click dropdown')

      if (this.isActive) {
        this.$parent.$emit('close')
      }
    }
  },
  template: `
     <div class="navbar-item navbar-item-dropdown js-menu-list-dropdown has-dropdown">

        <slot name="link" v-bind:toggleDropdown="toggleDropdown"></slot>

        <div class="menu-list__dropdown js-menu-list-container is-active" v-if="isActive">
          <slot></slot>
        </div>
     </div>
    `
})


/**
 * инициализация
 */
function start () {
  /**
   * элемент должен существовать
   */
  if (!document.getElementById('navbar-top')) {
    console.warn('runtime:navbar:warn #navbar-top not found')
    return
  }

  /* eslint no-unused-vars: 0 */
  const app = new Vue({
    store,
    /**
     * элемент должен существовать
     * на странице
     */
    el: '#navbar-top',
    data: function(){
      return {
        active: false
      }
    },
    mounted() {
      /**
       * по сути можно перенести логику управления
       * выпадающими списками, да в отдельный класс
       * и инициализировать здесь
       */

      /**
       * например чтобы закрывать
       * меню, когда пользователь "кликает"
       * вне меню, а меню открыто можно
       * инициировать закрытие меню
       */
      document.addEventListener('click', () => {
        // alert('a')
      })
    },
    methods: {
      /**
       * клик на пункте меню
       */
      dropdown($event) {
        console.log('navbar:dropdown: click on js-navbar-item-mobile', $event)
      },
      /**
       * кнопка "вернуться назад"
       * для мобильного варианта меню
       */
      back($event) {
        console.log('navbar:back', $event)
      },

      /**
       * добавление класса
       * к меню при клике на `burger`
       */
      toggle(force) {

        force = !!force

        this.active = !this.active

        // console.log(this.$refs)

        /**
         *
         * добавляем сцец. класс к body
         * чтобы иметь возможность переопределить
         * стили когда меню открыто или закрыто
         *
         */
        if (this.active) {
          document.querySelector('body').classList.add('has-menu-active')
        } else {
          document.querySelector('body').classList.remove('has-menu-active')

          //document.querySelector('.js-navbar-item-mobile').classList.remove('open') // удаление активного класса второго уровня меню
          //document.querySelector('.js-navbar-item-mobile .js-menu-list-container').style.display = 'none' // смена атрибута второго уровня меню
        }

        /**
         * если на странице несколько таких элементов (селекторов)
         * будет выбран первый
         *
         * Зачем? При открытии и закрытии меню - сброс состояния
         * иначе при повторном открытии меню останется
         * в предыдущем положении.
         *
         */
        document.querySelector('.js-navbar-item-mobile').classList.remove('open') // удаление активного класса второго уровня меню
        document.querySelector('.js-navbar-item-mobile .js-menu-list-container').style.display = 'none' // смена атрибута второго

        /**
         *
         * ЕСЛИ НЕТ ПОНИМАНИЯ КАК РЕШИТЬ
         * ЗАДАЧУ ПРАВИЛЬНО, НАДО СПРОСИТЬ!
         *
         */
      },
      callback () {
        const modal = this.$buefy.modal.open({
          parent: this,
          component: CallbackForm,
          canCancel: ['x'],
          width: 720
        })

        /**
         * после того, как данные успешно отправлены
         * на сервер, необходимо скрыть кнопку закрытия окна
         * вынудив пользователя нажать кнопку "ok", чтобы
         * явно сбросить настройки формы.
         *
         * Другой вариант полностью разрушать форму
         * при закрытии destroyOnHide: true
         */
        modal.$on('success', () => {
          // modal.$props.canCancel = false
        })
      }
    }
  })

  /**
   * scrollmagic
   *
   * для запуска функциональности добавить к nav.navbar
   * класс `navbar-sticky`. после прикрепления панели
   * добавляется класс `is-pinned`
   *
   */
  let sm_selector = '.navbar-sticky'
  let pinnedClass = 'is-pinned'
  let zIndex = 39
  let navbar = document.querySelector(sm_selector)

  if (!navbar) return

  const controller = new ScrollMagic.Controller({})
  const menuSceneOffset = 50
  const menuScene = new ScrollMagic.Scene({
    triggerElement: sm_selector,
    triggerHook: 'onLeave',
    offset: menuSceneOffset,
    duration: 0
  })
    .setClassToggle(sm_selector, pinnedClass)
    .setPin(sm_selector)
    .on('enter', function(e) {
      TweenMax.to(sm_selector, 0.3, {
        duration: 0.3,
        y: menuSceneOffset - 1,
        zIndex: zIndex
      })
    })
    .on('leave', function(e) {
      TweenMax.to(sm_selector, 0.2, {
        y: 0,
        zIndex: zIndex
      })
    })

  // async init
  setTimeout(function(){
    menuScene.addTo(controller)
  }, 100)
}

export default {
  start
}
