<template lang="pug">
  // форма записаться на прием в шапке
  .order-to-appointment-form.section: validation-observer(v-slot="{valid, invalid}")
    p.title.is-4.has-text-centered.has-text-weight-light Записаться на приём
    br
    b-notification.is-success.content.has-background-primary.has-text-centered(v-if="isSuccess" :closable="false")
      p.title.is-4 Спасибо! Ваша заявка отправлена.
      p.success-message как только получим вашу заявку, обязательно перезвоним
      p: button.button(@click.prevent="closeSuccess") понятно
    b-notification.is-danger(v-if="hasErrors")
      p.title.is-4 Ошибка!
      p не удалось обработать запрос, попробуйте еще раз, если ошибка повторяется перезагрузите страницу
    form.form(@submit.prevent="submit" ref="os_form" name="os_form" v-if="isNotSuccess")
      input(type="hidden" name="WEB_FORM_ID" value="6")
      input(type="hidden" name="form_text_54" value="да")
      .columns
        .column: validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_text_51" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия")
        .column: validation-provider(v-slot="{valid, invalid, dirty, errors}" rules="required")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_text_52" :disabled="isLock" v-model="formData.phone" placeholder="Телефон")
      .columns
        .column: validation-provider(v-slot="{errors, valid, dirty, invalid, pristine }" rules="required" name="Ваше сообщение")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_textarea_53" type="textarea" placeholder="Ваше сообщение * " v-model="formData.massage")
          p.help.is-danger: span(v-if="invalid")  {{ errors[0] }}  
      p.has-text-danger(style="margin-bottom: .75rem;") * - обязательные поля.
      p.is-size-7.
        Отправляя форму вы соглашаетесь на обработку ваших персональных данных
        в соответствие с <a href="/assets/files/Personal-data-processing-agreement.pdf" target="_blank">пользовательским соглашением сайта</a>. Передаваемая
        контактная информация используется исключительно для связи с Вами.
      br
      p: b-field
        button.button.is-primary(type="submit" :disabled="isLock || invalid" :class="{ 'is-loading': isLock }") отправить

</template>
<script>
import store from '@/store/index'
import { mapGetters, mapMutations } from 'vuex'
export default {
  store,
  data () {
    return {
      status: 'pristine',
      formId: 6,
      formData: {
        name: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapGetters(['isLock']),
    isSuccess() {
      return this.status === 'success'
    },
    hasErrors() {
      return this.status === 'error'
    },
    isNotSuccess() {
      return this.status !== 'success'
    }
  },
  methods: {
    ...mapMutations(['lock', 'unlock']),
    submit () {
      this.lock()
      const form = new FormData(this.$refs.os_form)
      this.$http.post('/platform/api/forms/6/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.unlock()
        this.status = 'success'
        this.formData.name = ''
        this.formData.phone = ''

        this.$parent.$emit('success')
      }, errors => {
        this.unlock()
        this.status = 'error'
        this.$buefy.toast.open({
          type: 'is-danger',
          message: `Ошибка! ${errors.status}`
        })
      })
    },
    closeSuccess() {
      this.status = 'pristine'
      this.$emit('close')
    }
  }
}
</script>
<style lang="sass" scoped>
@import 'vars'
.order-to-appointment-form.section
  padding: 1.4rem
.order-to-appointment-form
  background: #fff
  border-radius: 6px
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem

</style>
